import { Injectable } from '@angular/core';
import * as teamsLib from '@microsoft/teams-js';
import produce from 'immer';
import { UserService, UserSettingsQuery } from 'timeghost-api';
export enum FailedReason {
  /**
   * Authentication failed
   */
  AuthFailed = 'AuthFailed',
  /**
   * The application timed out
   */
  Timeout = 'Timeout',
  /**
   * The app failed for a different reason
   */
  Other = 'Other',
}

@Injectable({
  providedIn: 'root',
})
export class TeamsService {
  private _instance: typeof teamsLib;
  constructor(
    private userService: UserService,
    private userSettingsQuery: UserSettingsQuery,
  ) {}

  async initialize() {
    if (!this._instance) this._instance = teamsLib;
    await this._instance.app.initialize();
    window.microsoftTeams = this._instance; // add teams instance to window for api library
    window.teams = !!this._instance;
  }
  getInstance() {
    return this._instance;
  }
  isInitialized() {
    return !!this._instance?.app.isInitialized();
  }
  async setUserData() {
    const context = await this.getInstance()
      .app.getContext()
      .catch(() => null);
    if (!context) return;
    window.teamsMobile = ['android', 'ios'].includes(context.app.host.clientType);
    const user = this.userSettingsQuery.getValue();
    if (!user['referrer'])
      await new Promise<void>((resolve) =>
        this.userService.adapter
          .post(
            'update/users/:id'.replace(':id', user.id),
            produce(user, (draft: any) => {
              draft.referrer = 'teams';
              draft.referrerDate = new Date().toISOString();
              return draft;
            }),
          )
          .toPromise()
          .finally(resolve),
      );
  }
}
